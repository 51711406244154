<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
    <g id="Group_1647" data-name="Group 1647" transform="translate(-765 -35)">
      <path
        id="Path_2048"
        data-name="Path 2048"
        d="M14.333,27a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,14.333,27Z"
        transform="translate(754 34.667)"
        fill="#6c63ff"
      />
      <path
        id="Path_2049"
        data-name="Path 2049"
        d="M77.667,12a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,77.667,12Z"
        transform="translate(797.333 24.667)"
      />
      <path
        id="Path_2050"
        data-name="Path 2050"
        d="M74.667,13a61.667,61.667,0,1,0,61.667,61.667A61.667,61.667,0,0,0,74.667,13Z"
        transform="translate(755 25)"
      />
      <path
        id="Path_2051"
        data-name="Path 2051"
        d="M85.667,11a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,85.667,11Z"
        transform="translate(799.333 24)"
      />
      <path
        id="Path_2052"
        data-name="Path 2052"
        d="M88.333,22a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,88.333,22Z"
        transform="translate(803.333 31.333)"
        fill="#6c63ff"
      />
      <path
        id="Path_2053"
        data-name="Path 2053"
        d="M127.667,84A3.333,3.333,0,1,0,131,87.333,3.333,3.333,0,0,0,127.667,84Zm-110-25a6.667,6.667,0,1,0,6.667,6.667A6.667,6.667,0,0,0,17.667,59Z"
        transform="translate(754 56)"
      />
      <path
        id="Path_2054"
        data-name="Path 2054"
        d="M26.333,85a3.333,3.333,0,1,0,3.333,3.333A3.333,3.333,0,0,0,26.333,85Z"
        transform="translate(762 73.333)"
        fill="#6c63ff"
      />
      <path
        id="Path_2055"
        data-name="Path 2055"
        d="M20.167,63a4.167,4.167,0,1,0,4.167,4.167A4.167,4.167,0,0,0,20.167,63Zm4.167,25A1.667,1.667,0,1,0,26,89.667,1.667,1.667,0,0,0,24.333,88Zm98.333-55a1.667,1.667,0,1,0,1.667,1.667A1.667,1.667,0,0,0,122.667,33Z"
        transform="translate(757.333 38.667)"
        fill="#fff"
      />
      <g id="Group_1287" data-name="Group 1287" transform="translate(220.002 -223)">
        <g id="noun_experience_1705256" transform="translate(569.998 291)">
          <g id="Group_1286" data-name="Group 1286" transform="translate(0)">
            <g id="Group_1284" data-name="Group 1284">
              <path
                id="Path_2380"
                data-name="Path 2380"
                d="M28.68,80.883a2.954,2.954,0,0,0,1.384.375,2.085,2.085,0,0,0,1.633-.748,2.6,2.6,0,0,0,.48-2.194l-.53-3.116,2.248-2.207a2.491,2.491,0,0,0,.8-2.554,2.456,2.456,0,0,0-2.131-1.6l-3.112-.455-1.391-2.838a2.282,2.282,0,0,0-4.323,0l-1.391,2.838-3.112.455a2.457,2.457,0,0,0-2.132,1.6,2.488,2.488,0,0,0,.8,2.553L20.145,75.2l-.53,3.113a2.6,2.6,0,0,0,.479,2.2,2.357,2.357,0,0,0,3.016.373L25.9,79.409Z"
                transform="translate(-16.998 -31.64)"
              />
              <path
                id="Path_2381"
                data-name="Path 2381"
                d="M101.56,68.842l-3.112-.455-1.39-2.837a2.283,2.283,0,0,0-4.324,0l-1.391,2.838-3.112.455a2.456,2.456,0,0,0-2.131,1.6,2.491,2.491,0,0,0,.8,2.554L89.145,75.2l-.53,3.115a2.6,2.6,0,0,0,.48,2.195,2.362,2.362,0,0,0,3.017.372L94.9,79.409l2.786,1.474a2.958,2.958,0,0,0,1.384.375,2.082,2.082,0,0,0,1.632-.748,2.593,2.593,0,0,0,.479-2.193l-.53-3.116,2.248-2.207a2.489,2.489,0,0,0,.8-2.554A2.457,2.457,0,0,0,101.56,68.842Z"
                transform="translate(-36.892 -31.64)"
              />
              <path
                id="Path_2382"
                data-name="Path 2382"
                d="M121.553,47.871a2.32,2.32,0,0,0-1.974-1.51l-2.552-.393L115.874,43.5a2.147,2.147,0,0,0-4.094,0l-1.153,2.47-2.551.392A2.321,2.321,0,0,0,106.1,47.87a2.4,2.4,0,0,0,.7,2.446l1.885,1.942-.448,2.759a2.5,2.5,0,0,0,.474,2.1,2.206,2.206,0,0,0,2.845.338l2.267-1.26,2.267,1.26a2.691,2.691,0,0,0,1.306.367,1.974,1.974,0,0,0,1.541-.708,2.493,2.493,0,0,0,.472-2.1l-.448-2.757,1.884-1.941A2.4,2.4,0,0,0,121.553,47.871Z"
                transform="translate(-42.658 -25.461)"
              />
              <path
                id="Path_2383"
                data-name="Path 2383"
                d="M59.1,43.449A12.225,12.225,0,1,0,47,31.223,12.162,12.162,0,0,0,59.1,43.449Z"
                transform="translate(-25.648 -19)"
              />
              <path
                id="Path_2384"
                data-name="Path 2384"
                d="M66.763,88.741,70.5,84.884a3.19,3.19,0,0,0,.99-3.211,3.06,3.06,0,0,0-2.625-1.934l-5.12-.786-2.3-4.931a2.73,2.73,0,0,0-5.274,0l-2.3,4.93-5.121.786a3.06,3.06,0,0,0-2.626,1.935,3.185,3.185,0,0,0,.989,3.209l3.743,3.858L49.973,94.2a3.357,3.357,0,0,0,.589,2.825,2.832,2.832,0,0,0,3.683.348l4.566-2.537,4.564,2.537a3.69,3.69,0,0,0,1.788.515,2.426,2.426,0,0,0,1.895-.863,3.352,3.352,0,0,0,.591-2.823Z"
                transform="translate(-25.359 -33.888)"
              />
            </g>
          </g>
          <g id="Group_1285" data-name="Group 1285" transform="translate(7.84 22.872)">
            <path
              id="Path_2385"
              data-name="Path 2385"
              d="M35.61,60.567l.728,1.484,1.633.239A5.273,5.273,0,0,1,42.4,65.847a5.351,5.351,0,0,1-1.522,5.506L39.707,72.5l.276,1.621a6.637,6.637,0,0,1,.079.7s0-.008,0-.012a5.884,5.884,0,0,1,4.9-3.87l3.621-.556,1.654-3.544a5.535,5.535,0,0,1,10.423,0l1.654,3.546,3.62.556a5.884,5.884,0,0,1,4.9,3.867l0,.014a6.656,6.656,0,0,1,.079-.7l.276-1.621L70.02,71.351a5.349,5.349,0,0,1-1.521-5.5,5.274,5.274,0,0,1,4.431-3.557l1.632-.239.728-1.485a6.144,6.144,0,0,1,1.053-1.515A19.84,19.84,0,0,0,65.89,50.944a14.8,14.8,0,0,1-21.026-.138,19.217,19.217,0,0,0-10.52,8.027A6.06,6.06,0,0,1,35.61,60.567Z"
              transform="translate(-29.839 -50.807)"
            />
            <path
              id="Path_2386"
              data-name="Path 2386"
              d="M93.008,89.141l-1.463.775a5.219,5.219,0,0,1-6.515-1.067,5.088,5.088,0,0,1-.947-1.764,6.5,6.5,0,0,1-1.9,4.051l-2.73,2.814.024.148H90.386a3.663,3.663,0,0,0,3.675-3.492c.006-.3.009-.6.011-.9Z"
              transform="translate(-42.843 -60.997)"
            />
            <path
              id="Path_2387"
              data-name="Path 2387"
              d="M42.63,93.952,39.9,91.135A6.484,6.484,0,0,1,38,87.087a5.1,5.1,0,0,1-.947,1.763,4.942,4.942,0,0,1-3.809,1.77,5.743,5.743,0,0,1-2.7-.7l-1.464-.775-1.057.56c0,.3.01.606.018.905A3.682,3.682,0,0,0,31.721,94.1H42.607Z"
              transform="translate(-28.014 -60.998)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
    name: "ResumeHeaderIcon"
}
</script>

<style lang="scss" scoped>
:root {
  #Group_1284 path,
  #Group_1285 path {
    fill: #ef7974;
  }
  #Path_2053,
  #Path_2051,
  #Path_2049 {
    fill: #ec615b;
  }
  #Path_2050 {
    fill: #6c63ff;
    opacity: 0.147;
  }
}
:root.dark-theme {
  #Group_1284 path,
  #Group_1285 path {
    fill: #f8bf7b;
  }
  #Path_2053,
  #Path_2051,
  #Path_2049 {
    fill: #f8bf7b;
  }
  #Path_2050 {
    fill: #1e2129;
    opacity: 1;
  }
}
</style>