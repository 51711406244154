<template>
  <svg class="edu-cambridge-svg" width="92" height="90" viewBox="0 0 92 90">
    <g id="Group_1650" data-name="Group 1650" transform="translate(-235 -740)">
      <path
        id="Path_1687"
        data-name="Path 1687"
        d="M78.184,13a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,78.184,13Z"
        transform="translate(236.974 728.184)"
        fill="#6c63ff"
      />
      <path
        id="Path_1688"
        data-name="Path 1688"
        d="M57,12a45,45,0,1,0,45,45A45,45,0,0,0,57,12Z"
        transform="translate(223 728)"
      />
      <path
        id="Path_1689"
        data-name="Path 1689"
        d="M84.737,12a4.737,4.737,0,1,0,4.737,4.737A4.737,4.737,0,0,0,84.737,12Z"
        transform="translate(237.526 728)"
        fill="#6c63ff"
      />
      <path
        id="Path_1690"
        data-name="Path 1690"
        d="M14.368,24a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,14.368,24Z"
        transform="translate(225 730.21)"
        fill="#ee3e54"
      />
      <path
        id="Path_1691"
        data-name="Path 1691"
        d="M89.974,75.184a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,89.974,75.184ZM17.737,74a4.737,4.737,0,1,0,4.737,4.737A4.737,4.737,0,0,0,17.737,74Z"
        transform="translate(225.185 739.421)"
        fill="#6c63ff"
      />
      <path
        id="Path_1692"
        data-name="Path 1692"
        d="M24.368,81a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,24.368,81Z"
        transform="translate(226.842 740.71)"
        fill="#ee3e54"
      />
      <path
        id="Path_1693"
        data-name="Path 1693"
        d="M75.967,81.382a2.94,2.94,0,1,0,2.94,2.94A2.94,2.94,0,0,0,75.967,81.382ZM16.184,47a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,16.184,47Z"
        transform="translate(225.553 734.447)"
        fill="#fff"
      />
      <path
        id="Path_1694"
        data-name="Path 1694"
        d="M86.184,30a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,86.184,30Z"
        transform="translate(238.447 731.316)"
        fill="#6c63ff"
      />
      <path
        id="Path_1695"
        data-name="Path 1695"
        d="M80.368,64a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,80.368,64Z"
        transform="translate(237.158 737.579)"
        fill="#fff"
      />
      <g id="Group_1134" data-name="Group 1134" transform="translate(6.251 2.407)">
        <path
          id="Path_1696"
          data-name="Path 1696"
          d="M26.175,43.625V66.983h1.257V43.625"
          transform="translate(225.425 731.228)"
          fill="#6d5856"
        />
        <path
          id="Path_1697"
          data-name="Path 1697"
          d="M20.229,42.84l27.66-14.368L75.55,42.836,47.889,57.2Z"
          transform="translate(226.033 730.909)"
          fill="#a1b7d0"
        />
        <path
          id="Path_1698"
          data-name="Path 1698"
          d="M47.717,29.272,73.865,42.853,47.717,56.436,21.568,42.856,47.717,29.272m0-1.571L18.543,42.856,47.717,58.007,76.891,42.852Z"
          transform="translate(226.205 730.892)"
          fill="#6d5856"
        />
        <path
          id="Path_1699"
          data-name="Path 1699"
          d="M33.7,58.954V44.582l.058-.163c.688-1.919,6.8-3.989,15.471-3.989S64.014,42.5,64.7,44.418l.058.163V58.954Z"
          transform="translate(224.655 731.161)"
          fill="#b0c1d4"
        />
        <path
          id="Path_1700"
          data-name="Path 1700"
          d="M49.159,41.159c9.069,0,14.433,2.273,14.889,3.544l.013.035V58.254h-29.8V44.737l.013-.035c.456-1.272,5.821-3.544,14.889-3.544m0-1.43c-8.317,0-15.16,1.94-16.054,4.435H33V59.684H65.318V44.164h-.105C64.318,41.669,57.476,39.729,49.159,39.729Z"
          transform="translate(224.726 731.146)"
          fill="#6d5856"
        />
        <path
          id="Path_1701"
          data-name="Path 1701"
          d="M49.23,55.572c-8.577,0-15.53,2-15.53,4.456s6.953,4.456,15.53,4.456,15.53-2,15.53-4.456S57.808,55.572,49.23,55.572Z"
          transform="translate(224.655 731.48)"
          fill="#e5e5e5"
        />
        <path
          id="Path_1702"
          data-name="Path 1702"
          d="M49.159,56.3c9.68,0,14.9,2.656,14.9,3.741s-5.222,3.742-14.9,3.742-14.9-2.656-14.9-3.741,5.222-3.742,14.9-3.742m0-1.43C40.235,54.871,33,57.187,33,60.042s7.235,5.172,16.159,5.172S65.318,62.9,65.318,60.043,58.083,54.871,49.159,54.871Z"
          transform="translate(224.726 731.465)"
          fill="#6d5856"
        />
        <path
          id="Path_1703"
          data-name="Path 1703"
          d="M24.384,68.466l-.372-.923A3.708,3.708,0,0,1,23.7,66.07a3.19,3.19,0,0,1,2.962-3.37,3.189,3.189,0,0,1,2.962,3.37,3.668,3.668,0,0,1-.534,1.922l-.027.044-2.433,5.609Z"
          transform="translate(225.678 731.63)"
          fill="#ecad6f"
        />
        <path
          id="Path_1704"
          data-name="Path 1704"
          d="M26.591,63.646A2.512,2.512,0,0,1,28.925,66.3a2.888,2.888,0,0,1-.421,1.512l-.055.089-.042.1L26.559,72.26l-1.19-2.743h.022l-.886-2.06a2.915,2.915,0,0,1-.248-1.157,2.512,2.512,0,0,1,2.334-2.655m0-1.43A3.862,3.862,0,0,0,23,66.3a4.5,4.5,0,0,0,.376,1.787h-.029l3.212,7.4,2.975-6.86a4.455,4.455,0,0,0,.647-2.33A3.862,3.862,0,0,0,26.591,62.216ZM56.14,53.61a33.869,33.869,0,0,1,4.871,1.432.44.44,0,0,0,.568-.323.53.53,0,0,0-.284-.646,34.467,34.467,0,0,0-4.989-1.466.456.456,0,0,0-.524.406.51.51,0,0,0,.357.6Zm-4.963-.721q1.341.106,2.664.3a.463.463,0,0,0,.5-.439.5.5,0,0,0-.386-.573q-1.351-.2-2.72-.309a.472.472,0,0,0-.478.474.493.493,0,0,0,.417.544ZM35.261,55.042A36.683,36.683,0,0,1,48.144,52.77q.311,0,.622.005a.514.514,0,0,0,.013-1.02c-.212,0-.424-.005-.636-.005a37.443,37.443,0,0,0-13.167,2.324.528.528,0,0,0-.284.645.438.438,0,0,0,.567.323Z"
          transform="translate(225.749 731.4)"
          fill="#6d5856"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EduCambridge"
}
</script>

<style lang="scss" scoped>
:root {
  .edu-cambridge-svg {
    #Path_1688 {
      fill: #efeeff;
    }
  }
}
:root.dark-theme {
  .edu-cambridge-svg {
    #Path_1688 {
      fill: #40414b;
    }
  }
}
</style>