<template>
  <div class="resume-component">
    <div class="component-nav-icon">
      <!-- <img src="@/assets/icons/resume_header_icon.svg" alt srcset /> -->
      <ResumeHeaderIcon />
      <div class="c-n-title">
        <span>RESUME</span>
        <span>&nbsp;</span>
      </div>
    </div>

    <div class="r-c-content">
      <div class="r-c-c-title">
        <span>Job Experience</span>
      </div>
      <div class="r-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card job-current elevation-3" width="425">
                <div class="job-current-tile">
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/job_present.svg" alt srcset /> -->
                    <JobPresent />
                  </v-avatar>
                  <div class="job-desc">
                    <v-card-title>Accenture AI</v-card-title>
                    <v-card-subtitle>
                      Dec 2018
                      <span>-</span>
                      Present
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card job-past elevation-3" width="425">
                <div class="job-past-tile">
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/job_past.svg" alt srcset /> -->
                    <JobPast />
                  </v-avatar>
                  <div class="job-desc">
                    <v-card-title>Kogentix INC.</v-card-title>
                    <v-card-subtitle>
                      Jun 2017
                      <span>-</span>
                      Nov 2018
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card job-past elevation-3" width="425">
                <div class="job-past-tile">
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/job_past.svg" alt srcset /> -->
                    <JobPast />
                  </v-avatar>
                  <div class="job-desc">
                    <v-card-title>Flutura</v-card-title>
                    <v-card-subtitle>
                      Aug 2015
                      <span>-</span>
                      May 2017
                    </v-card-subtitle>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="r-c-c-title">
        <span>Experience</span>
      </div>
      <div class="r-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card edu-card elevation-0" width="425">
                <v-avatar class="mt-2" size="120" tile>
                  <!-- <img src="@/assets/icons/edu_cambridge.svg" alt srcset /> -->
                  <EduCambridge />
                </v-avatar>
                <v-card-title>Cambridge Institute of Technology</v-card-title>
                <v-card-text>B.E - Computer Science Engineering</v-card-text>
                <v-card-subtitle>2011 - 2015</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card edu-card elevation-0" width="425">
                <v-avatar class="mt-2" size="120" tile>
                  <!-- <img src="@/assets/icons/edu_jain.svg" alt srcset /> -->
                  <EduJain />
                </v-avatar>
                <v-card-title>Sri Bhagawan Mahaveer Jain College</v-card-title>
                <v-card-text>Pre University Course</v-card-text>
                <v-card-subtitle>2009 - 2011</v-card-subtitle>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card class="r-c-c-tile-card edu-card elevation-0" width="425">
                <v-avatar class="mt-2" size="120" tile>
                  <!-- <img src="@/assets/icons/edu_school.svg" alt srcset /> -->
                  <EduSchool />
                </v-avatar>
                <v-card-title>Sacred Heart Boys High School</v-card-title>
                <v-card-text>Secondary School Leaving Certificate</v-card-text>
                <v-card-subtitle>2006 - 2009</v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="r-c-c-title">
        <span>Github Projects</span>
      </div>
      <div class="r-c-c-tiles-body">
        <v-container fluid ma-0 pa-0>
          <v-row dense>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card
                class="githb-proj ctry-st-cty elevation-0 pt-2 pb-2"
                width="425"
              >
                <div class="d-flex justify-space-around align-center">
                  <div class="github-desc">
                    <v-card-title>Country-State-Cities</v-card-title>
                    <v-card-text>React Application</v-card-text>
                    <v-btn text small rounded @click="openCSC">Open</v-btn>
                  </div>
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/github_cn_st_ci.svg" alt srcset /> -->
                    <GithubCountryStates />
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card
                class="githb-proj star-wars elevation-0 pt-2 pb-2"
                width="425"
              >
                <div class="d-flex justify-space-around align-center">
                  <div class="github-desc">
                    <v-card-title>Star-Wars App</v-card-title>
                    <v-card-text>Angular Application</v-card-text>
                    <v-btn text small rounded @click="openStarWars">Open</v-btn>
                  </div>
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/github_star_wars.svg" alt srcset /> -->
                    <svg
                      class="star-wars-svg"
                      width="142"
                      height="120"
                      viewBox="0 0 142 120"
                    >
                      <g id="Group_1652" data-name="Group 1652" transform="translate(-885.5 -401)">
                        <g id="icons8-star-wars" transform="translate(905 418)">
                          <path
                            id="Path_1995"
                            data-name="Path 1995"
                            d="M107.708,30.083H99.229l-.458-.517a10.389,10.389,0,0,0,3.437-7.75c0-5.425-3.9-9.817-8.708-9.817H77.458V39.383l-.687-2.325L69.9,13.808,69.208,12H56.375l-.458,1.808-1.833,6.458V12H20.854c-4.354,0-7.563,3.875-7.563,8.783a6.934,6.934,0,0,0,1.146,4.133h0c.458.517,2.292,3.1,3.438,4.908H0V40.158H22.458c4.354,0,7.792-3.875,7.792-8.783A6.934,6.934,0,0,0,29.1,27.242l-3.437-5.167h8.021V40.158H44.229V22.075H53.4L49.271,36.8l-1.146,3.617H58.667l.458-1.808,1.146-3.358h5.5l1.146,3.358.458,1.808H88V34.992c1.375,1.55,2.75,2.842,3.667,3.875a4.982,4.982,0,0,0,3.208,1.55H110V30.083ZM35.521,48.942l-6.875,23.25L28.187,74h-7.1l-.458-1.808-2.292-6.975-2.063,6.975L15.583,74H8.25l-.458-1.808L.917,48.942,0,45.583H10.771l.458,1.808.687,2.842.917-2.842.688-1.808h9.625l.458,1.808.917,2.842.688-2.842.458-1.808h11Zm59.125-3.358c-4.354,0-7.792,3.875-7.792,8.783A6.934,6.934,0,0,0,88,58.5h0c.458.517,2.292,3.1,3.667,4.908H81.125L80.9,63.15a10.389,10.389,0,0,0,3.438-7.75c0-5.425-3.9-9.817-8.708-9.817H59.583V74l-.917-3.358-6.875-23.25-.458-1.808H38.5l-.458,1.808L31.4,70.642,30.25,74H40.562l.458-1.808,1.146-3.358h5.5l1.146,3.358L49.271,74H70.354V68.575a48.955,48.955,0,0,0,3.9,3.875A4.4,4.4,0,0,0,77.687,74H96.708c4.354,0,7.792-3.875,7.792-8.783a6.934,6.934,0,0,0-1.146-4.133l-3.437-5.167H110V45.583H94.646Z"
                            transform="translate(0 0)"
                          />
                          <path
                            id="Path_1996"
                            data-name="Path 1996"
                            d="M51.556,13H19.812c-3.057,0-5.408,2.74-5.408,6.576a5.058,5.058,0,0,0,.705,2.74c.705,1.1,4.938,7.124,4.938,7.124,0,.274.235.548.235.822a1.481,1.481,0,0,1-1.411,1.918H1v5.48H21.693c3.057,0,5.643-2.74,5.643-6.576a5.058,5.058,0,0,0-.705-2.74L22.163,21.22c0-.274-.235-.548-.235-.822a1.755,1.755,0,0,1,1.646-1.918H35.8V37.659h6.114V18.48H52.026V13Zm16.46,0H58.14L51.321,37.659H57.2l1.646-5.48h8.935l1.646,5.48h5.879Zm-7.76,13.7,2.822-9.864L65.9,26.7Zm40.21,5.48a2.354,2.354,0,0,1-1.881-1.1A25.252,25.252,0,0,0,96,28.069c2.822-.822,4.938-3.836,4.938-7.4,0-4.384-3.057-7.672-6.584-7.672H80.244V37.659h6.349V28.343s5.408,6.028,7.76,8.494a2.64,2.64,0,0,0,1.881.822h12.933v-5.48ZM86.593,18.48h6.114a2.6,2.6,0,0,1,2.587,2.74,2.6,2.6,0,0,1-2.587,2.74H86.593ZM26.866,48.619,23.809,59.3,20.752,48.619H14.168L10.876,59.3,7.819,48.619H1.941L8.995,73.278h4L17.46,58.208l4.468,15.069h4L32.98,48.619ZM99.055,54.1h10.111v-5.48h-13.4c-3.057,0-5.643,2.74-5.643,6.576a5.058,5.058,0,0,0,.705,2.74c.705.822,5.173,6.85,5.173,6.85,0,.274.235.548.235.822,0,1.1-.705,1.918-1.411,1.918H82.36a2.354,2.354,0,0,1-1.881-1.1c-1.176-1.644-2.351-3.014-2.351-3.014,2.822-.822,4.938-3.836,4.938-7.4,0-4.384-3.057-7.672-6.584-7.672H62.138V73h6.349V63.688s5.408,6.028,7.76,8.494A2.64,2.64,0,0,0,78.128,73H97.645c3.057,0,5.643-2.466,5.643-6.576a5.058,5.058,0,0,0-.705-2.74l-4.7-6.85c0-.274-.235-.548-.235-.822C97.645,54.92,98.35,54.1,99.055,54.1ZM74.6,59.578H68.487V54.1H74.6a2.744,2.744,0,0,1,0,5.48ZM49.675,48.619H39.8L32.98,73.278h5.643l1.646-5.48H49.2l1.646,5.48h5.879Zm-7.76,13.7,2.822-9.864,2.822,9.864Z"
                            transform="translate(-0.083 -0.139)"
                          />
                        </g>
                        <path
                          id="Path_2577"
                          data-name="Path 2577"
                          d="M142.517,42.181C126.4,16.666,92.354,6.316,58.871,17.463c-17.7,5.893-34.769,17.7-43.227,40.565C.524,98.9,32.27,111.953,53.029,116.114a102.686,102.686,0,0,1,28.131,9.992c15.542,8.277,43.053,15.612,63.8-18.867C159.994,82.248,153.252,59.177,142.517,42.181Z"
                          transform="translate(873.803 387.989)"
                        />
                      </g>
                    </svg>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
            <v-col class="px-2 pb-6" cols="12" sm="6" md="6" lg="4">
              <v-card
                class="githb-proj pizza-store elevation-0 pt-2 pb-2"
                width="425"
              >
                <div class="d-flex justify-space-around align-center">
                  <div class="github-desc">
                    <v-card-title>Pizza-Store App</v-card-title>
                    <v-card-text>Angular Application</v-card-text>
                    <v-btn text small rounded @click="openPizzaStore">Open</v-btn>
                  </div>
                  <v-avatar class="ma-2" size="125" tile>
                    <!-- <img src="@/assets/icons/github_pizza_store.svg" alt srcset /> -->
                    <GithubPizzaStore />
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import ResumeHeaderIcon from "../SVG/ResumeHeaderIcon";
import JobPresent from "../SVG/JobPresent";
import JobPast from "../SVG/JobPast";
import EduCambridge from "../SVG/EduCambridge";
import EduJain from "../SVG/EduJain";
import EduSchool from "../SVG/EduSchool";
import GithubCountryStates from "../SVG/GithubCountryStates";
import GithubPizzaStore from "../SVG/GithubPizzaStore";
export default {
  name: "Resume",
  components:{
    ResumeHeaderIcon, JobPresent, JobPast, EduCambridge, EduJain, EduSchool,
    GithubCountryStates, GithubPizzaStore
  },
  data() {
    return {
      countryStateCity: "https://dbnaveen.github.io/country-states-cities/",
      starWarApp: "https://dbnaveen.github.io/star_wars_application/",
      pizzaStore: "https://dbnaveen.github.io/online_pizza/"
    }
  },
  methods: {
    openCSC() {
      window.open(this.countryStateCity, "_blank");
    },
    openStarWars() {
      window.open(this.starWarApp, "_blank");
    },
    openPizzaStore() {
      window.open(this.pizzaStore, "_blank");
    },
  }
}
</script>

<style lang="scss" scoped>
:root{
  .star-wars-svg {
    #Path_2577 {
      fill: #e6aa24;
      opacity: 0.2;
    }
    #Path_1995 {
      fill: #ffc107;
    }
  }
}
:root.dark-theme {
  .star-wars-svg {
    #Path_2577 {
      fill: #F8F8FD;
      opacity: 0.13;
    }
    #Path_1995 {
      fill: #F8BF7B;
    }    
  }
}
</style>