<template>
  <svg class="job-present-svg" width="100" height="100" viewBox="0 0 100 100">
    <g id="Group_1648" data-name="Group 1648" transform="translate(-209.813 -393.5)">
      <g id="Group_1078" data-name="Group 1078" transform="translate(-16 -34.9)">
        <path
          id="Path_1456"
          data-name="Path 1456"
          d="M1320.534,1436.442h-13.509v-6.866a5.676,5.676,0,0,0-5.55-5.776h-16.525a5.676,5.676,0,0,0-5.55,5.776v6.866h-12.525a4.873,4.873,0,0,0-4.775,4.97v36.2a4.873,4.873,0,0,0,4.775,4.97h53.679a4.873,4.873,0,0,0,4.776-4.97v-36.225A4.913,4.913,0,0,0,1320.534,1436.442Zm-36.045-6.866a.475.475,0,0,1,.461-.479h16.525a.475.475,0,0,1,.461.479v6.866h-17.447Z"
          transform="translate(-1012.017 -977.778)"
        />
        <g id="Group_1031" data-name="Group 1031" transform="translate(246.648 456.288)">
          <path
            id="Path_1457"
            data-name="Path 1457"
            d="M1307.554,1475.2h-53.68a3.961,3.961,0,0,0-3.875,4.032v24.237h61.449v-24.237A3.991,3.991,0,0,0,1307.554,1475.2Z"
            transform="translate(-1249.099 -1474.263)"
            fill="#fff"
          />
          <path
            id="Path_1458"
            data-name="Path 1458"
            d="M1304.155,1470.9h-53.68a4.873,4.873,0,0,0-4.775,4.97v25.152h63.23V1475.87A4.9,4.9,0,0,0,1304.155,1470.9Zm3.875,29.185h-61.45v-24.237a3.961,3.961,0,0,1,3.875-4.032h53.68a3.961,3.961,0,0,1,3.875,4.032v24.237Z"
            transform="translate(-1245.7 -1470.9)"
            fill="#05033d"
          />
        </g>
        <path
          id="Path_1459"
          data-name="Path 1459"
          d="M1307.554,1649.732h-53.68A3.961,3.961,0,0,1,1250,1645.7v3.051a3.961,3.961,0,0,0,3.875,4.032h53.68a3.961,3.961,0,0,0,3.875-4.032V1645.7A3.961,3.961,0,0,1,1307.554,1649.732Z"
          transform="translate(-1002.452 -1151.313)"
          fill="#ec615b"
        />
        <path
          id="Path_1460"
          data-name="Path 1460"
          d="M1249.821,1617.077a3.961,3.961,0,0,0,3.874,4.033h53.68a3.961,3.961,0,0,0,3.875-4.033V1609.1H1249.8v7.978Z"
          transform="translate(-1002.294 -1122.69)"
          fill="rgba(236,97,91,0.6)"
        />
        <path
          id="Path_1461"
          data-name="Path 1461"
          d="M1245.7,1604.8v11.966a4.873,4.873,0,0,0,4.775,4.97h53.68a4.873,4.873,0,0,0,4.775-4.97V1604.8Zm62.33,11.966a3.961,3.961,0,0,1-3.875,4.033h-53.68a3.961,3.961,0,0,1-3.875-4.033v-11.029h61.45v11.029Z"
          transform="translate(-999.053 -1119.328)"
          fill="#05033d"
        />
        <path
          id="Path_1462"
          data-name="Path 1462"
          d="M1381.639,1591.8h-4.21a2.482,2.482,0,0,0-2.43,2.528v4.381a2.482,2.482,0,0,0,2.43,2.528h4.21a2.482,2.482,0,0,0,2.429-2.528v-4.381A2.482,2.482,0,0,0,1381.639,1591.8Zm1.257,6.016a2.19,2.19,0,0,1-2.136,2.223h-2.471a2.19,2.19,0,0,1-2.136-2.223v-2.572a2.19,2.19,0,0,1,2.136-2.223h2.471a2.19,2.19,0,0,1,2.136,2.223Z"
          transform="translate(-1101.272 -1109.161)"
          fill="#ec615b"
        />
        <path
          id="Path_1463"
          data-name="Path 1463"
          d="M1378.24,1587.6h-4.21a3.4,3.4,0,0,0-3.33,3.466v4.381a3.4,3.4,0,0,0,3.33,3.466h4.21a3.4,3.4,0,0,0,3.33-3.466v-4.381A3.409,3.409,0,0,0,1378.24,1587.6Zm2.43,7.847a2.483,2.483,0,0,1-2.43,2.528h-4.21a2.483,2.483,0,0,1-2.429-2.528v-4.381a2.482,2.482,0,0,1,2.429-2.528h4.21a2.482,2.482,0,0,1,2.43,2.528Z"
          transform="translate(-1097.873 -1105.876)"
          fill="#05033d"
        />
        <path
          id="Path_1464"
          data-name="Path 1464"
          d="M1388.428,1601.6h-2.471a1.286,1.286,0,0,0-1.257,1.308v2.572a1.286,1.286,0,0,0,1.257,1.308h2.471a1.287,1.287,0,0,0,1.256-1.308v-2.572A1.287,1.287,0,0,0,1388.428,1601.6Z"
          transform="translate(-1108.94 -1116.825)"
          fill="#fff"
        />
        <path
          id="Path_1465"
          data-name="Path 1465"
          d="M1385.108,1597.4h-2.471a2.19,2.19,0,0,0-2.136,2.223v2.572a2.19,2.19,0,0,0,2.136,2.223h2.471a2.19,2.19,0,0,0,2.136-2.223v-2.572A2.177,2.177,0,0,0,1385.108,1597.4Zm1.256,4.8a1.286,1.286,0,0,1-1.256,1.308h-2.471a1.287,1.287,0,0,1-1.257-1.308v-2.572a1.287,1.287,0,0,1,1.257-1.308h2.471a1.286,1.286,0,0,1,1.256,1.308Z"
          transform="translate(-1105.62 -1113.54)"
          fill="#05033d"
        />
        <path
          id="Path_1466"
          data-name="Path 1466"
          d="M1353.695,1417.1H1337.17a4.773,4.773,0,0,0-4.671,4.86v6.866h3.331v-6.866a1.378,1.378,0,0,1,1.34-1.4h16.525a1.379,1.379,0,0,1,1.341,1.4v6.866h3.33v-6.866A4.773,4.773,0,0,0,1353.695,1417.1Z"
          transform="translate(-1067.672 -972.538)"
          fill="#ec615b"
        />
        <path
          id="Path_1467"
          data-name="Path 1467"
          d="M1350.375,1412.8H1333.85a5.677,5.677,0,0,0-5.55,5.776v7.8h5.11v-7.8a.475.475,0,0,1,.461-.48H1350.4a.475.475,0,0,1,.461.48v7.8h5.111v-7.8A5.739,5.739,0,0,0,1350.375,1412.8Zm4.671,12.664h-3.33V1418.6a1.378,1.378,0,0,0-1.341-1.395H1333.85a1.378,1.378,0,0,0-1.341,1.395v6.866h-3.33V1418.6a4.772,4.772,0,0,1,4.67-4.86h16.525a4.773,4.773,0,0,1,4.671,4.86Z"
          transform="translate(-1064.352 -969.175)"
          fill="#05033d"
        />
        <path
          id="Path_1468"
          data-name="Path 1468"
          d="M1495.816,1403.374h2.346v-2.441a.608.608,0,1,1,1.214,0v2.441h2.346a.633.633,0,0,1,0,1.264h-2.346v2.441a.62.62,0,0,1-.607.632.593.593,0,0,1-.44-.2.693.693,0,0,1-.188-.458v-2.441h-2.346a.662.662,0,0,1-.44-.174.591.591,0,0,1-.167-.458A.649.649,0,0,1,1495.816,1403.374Z"
          transform="translate(-1196.285 -959.4)"
        />
        <path
          id="Path_1469"
          data-name="Path 1469"
          d="M1257.949,1713.473h2.345v-2.441a.608.608,0,1,1,1.215,0v2.441h2.346a.633.633,0,0,1,0,1.264h-2.346v2.441a.621.621,0,0,1-.608.632.591.591,0,0,1-.44-.2.694.694,0,0,1-.188-.458v-2.441h-2.345a.659.659,0,0,1-.44-.174.622.622,0,0,1,.461-1.068Z"
          transform="translate(-1008.223 -1201.911)"
        />
        <g id="Group_1038" data-name="Group 1038" transform="translate(303.239 505.569)">
          <path
            id="Path_1470"
            data-name="Path 1470"
            d="M1518.706,1697a2.923,2.923,0,1,1-2.807,2.921A2.866,2.866,0,0,1,1518.706,1697Zm0,1.787a1.134,1.134,0,0,0,0,2.267,1.1,1.1,0,0,0,1.089-1.134A1.132,1.132,0,0,0,1518.706,1698.787Z"
            transform="translate(-1515.9 -1697)"
          />
        </g>
        <g id="Group_1039" data-name="Group 1039" transform="translate(238.313 461.715)">
          <path
            id="Path_1471"
            data-name="Path 1471"
            d="M1208.706,1495.8a2.923,2.923,0,1,1-2.807,2.92A2.865,2.865,0,0,1,1208.706,1495.8Zm0,1.787a1.134,1.134,0,0,0,0,2.267,1.1,1.1,0,0,0,1.089-1.134A1.131,1.131,0,0,0,1208.706,1497.587Z"
            transform="translate(-1205.9 -1495.8)"
          />
        </g>
      </g>
      <path
        id="Path_1523"
        data-name="Path 1523"
        d="M329.307,175.3c0,22.9-17.074,42.2-40.372,48.124a60.7,60.7,0,0,1-11.493,1.772c-.188.011-.376.022-.565.03q-1.509.074-3.041.074-2.663,0-5.264-.223-1.443-.121-2.861-.309a58.586,58.586,0,0,1-24.808-9.23q-1.34-.891-2.616-1.854a53.237,53.237,0,0,1-4.508-3.8c-9.548-8.979-1.588-28.146,3.389-40.212C250.6,137.1,243.2,125.3,273.836,125.3S329.307,147.682,329.307,175.3Z"
        transform="translate(-19.494 268.203)"
        opacity="0.104"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "JobPresent"
}
</script>

<style lang="scss" scoped>
:root {
  .job-present-svg {
    #Path_1523,
    #Path_1469,
    #Path_1470,
    #Path_1471,
    #Path_1468 {
      fill: #6c63ff;
    }
    #Path_1456 {
      fill: #e9f2f3;
    }
  }
}
:root.dark-theme {
  .job-present-svg {
    #Path_1523 {
      fill: #e7eaec;
    }
    #Path_1469,
    #Path_1470,
    #Path_1471,
    #Path_1468 {
      fill: #f8bf7b;
    }
    #Path_1456 {
      fill: #56575d;
    }
  }
}
</style>