<template>
  <svg class="job-past-svg" width="100" height="100" viewBox="0 0 100 100">
    <g id="Group_1649" data-name="Group 1649" transform="translate(-734 -394)">
      <g id="Group_1094" data-name="Group 1094" transform="translate(-17.313 -50)">
        <path
          id="Path_1456"
          data-name="Path 1456"
          d="M1320.534,1436.442h-13.509v-6.866a5.676,5.676,0,0,0-5.55-5.776H1284.95a5.676,5.676,0,0,0-5.55,5.776v6.866h-12.525a4.873,4.873,0,0,0-4.775,4.97v36.2a4.873,4.873,0,0,0,4.775,4.97h53.68a4.873,4.873,0,0,0,4.775-4.97v-36.225A4.913,4.913,0,0,0,1320.534,1436.442Zm-36.045-6.866a.475.475,0,0,1,.461-.479h16.525a.475.475,0,0,1,.461.479v6.866h-17.447Z"
          transform="translate(-487.017 -962.678)"
        />
        <g id="Group_1031" data-name="Group 1031" transform="translate(771.647 471.388)">
          <path
            id="Path_1457"
            data-name="Path 1457"
            d="M1307.554,1475.2h-53.679a3.961,3.961,0,0,0-3.875,4.032v24.237h61.45v-24.237A3.992,3.992,0,0,0,1307.554,1475.2Z"
            transform="translate(-1249.099 -1474.263)"
            fill="#fff"
          />
          <path
            id="Path_1458"
            data-name="Path 1458"
            d="M1304.155,1470.9h-53.68a4.873,4.873,0,0,0-4.775,4.97v25.153h63.23V1475.87A4.9,4.9,0,0,0,1304.155,1470.9Zm3.875,29.185h-61.45v-24.237a3.961,3.961,0,0,1,3.875-4.032h53.679a3.961,3.961,0,0,1,3.875,4.032v24.237Z"
            transform="translate(-1245.7 -1470.9)"
            fill="#393e46"
          />
        </g>
        <path
          id="Path_1459"
          data-name="Path 1459"
          d="M1307.554,1649.732h-53.679A3.961,3.961,0,0,1,1250,1645.7v3.051a3.961,3.961,0,0,0,3.875,4.032h53.679a3.961,3.961,0,0,0,3.875-4.032V1645.7A3.961,3.961,0,0,1,1307.554,1649.732Z"
          transform="translate(-477.452 -1136.213)"
          fill="#a0a0a0"
        />
        <path
          id="Path_1460"
          data-name="Path 1460"
          d="M1249.821,1617.077a3.961,3.961,0,0,0,3.875,4.033h53.679a3.961,3.961,0,0,0,3.875-4.033V1609.1H1249.8v7.977Z"
          transform="translate(-477.294 -1107.59)"
          fill="#ebebeb"
        />
        <path
          id="Path_1461"
          data-name="Path 1461"
          d="M1245.7,1604.8v11.966a4.873,4.873,0,0,0,4.775,4.97h53.68a4.873,4.873,0,0,0,4.775-4.97V1604.8Zm62.33,11.966a3.961,3.961,0,0,1-3.875,4.033h-53.68a3.961,3.961,0,0,1-3.875-4.033v-11.029h61.45v11.029Z"
          transform="translate(-474.052 -1104.228)"
          fill="#393e46"
        />
        <path
          id="Path_1462"
          data-name="Path 1462"
          d="M1381.639,1591.8h-4.21a2.482,2.482,0,0,0-2.429,2.528v4.381a2.482,2.482,0,0,0,2.429,2.528h4.21a2.482,2.482,0,0,0,2.43-2.528v-4.381A2.482,2.482,0,0,0,1381.639,1591.8Zm1.257,6.016a2.19,2.19,0,0,1-2.136,2.223h-2.471a2.19,2.19,0,0,1-2.136-2.223v-2.572a2.19,2.19,0,0,1,2.136-2.223h2.471a2.19,2.19,0,0,1,2.136,2.223Z"
          transform="translate(-576.272 -1094.061)"
          fill="#a0a0a0"
        />
        <path
          id="Path_1463"
          data-name="Path 1463"
          d="M1378.24,1587.6h-4.21a3.4,3.4,0,0,0-3.33,3.466v4.381a3.4,3.4,0,0,0,3.33,3.466h4.21a3.4,3.4,0,0,0,3.33-3.466v-4.381A3.408,3.408,0,0,0,1378.24,1587.6Zm2.43,7.847a2.483,2.483,0,0,1-2.43,2.528h-4.21a2.482,2.482,0,0,1-2.43-2.528v-4.381a2.482,2.482,0,0,1,2.43-2.528h4.21a2.482,2.482,0,0,1,2.43,2.528Z"
          transform="translate(-572.873 -1090.776)"
          fill="#393e46"
        />
        <path
          id="Path_1464"
          data-name="Path 1464"
          d="M1388.428,1601.6h-2.472a1.287,1.287,0,0,0-1.256,1.308v2.572a1.286,1.286,0,0,0,1.256,1.308h2.472a1.286,1.286,0,0,0,1.256-1.308v-2.572A1.286,1.286,0,0,0,1388.428,1601.6Z"
          transform="translate(-583.94 -1101.724)"
          fill="#fff"
        />
        <path
          id="Path_1465"
          data-name="Path 1465"
          d="M1385.108,1597.4h-2.471a2.19,2.19,0,0,0-2.136,2.223v2.572a2.19,2.19,0,0,0,2.136,2.223h2.471a2.19,2.19,0,0,0,2.136-2.223v-2.572A2.177,2.177,0,0,0,1385.108,1597.4Zm1.257,4.8a1.287,1.287,0,0,1-1.257,1.308h-2.471a1.287,1.287,0,0,1-1.257-1.308v-2.572a1.287,1.287,0,0,1,1.257-1.308h2.471a1.287,1.287,0,0,1,1.257,1.308Z"
          transform="translate(-580.62 -1098.44)"
          fill="#393e46"
        />
        <path
          id="Path_1466"
          data-name="Path 1466"
          d="M1353.7,1417.1h-16.525a4.773,4.773,0,0,0-4.671,4.861v6.866h3.33v-6.866a1.378,1.378,0,0,1,1.341-1.395H1353.7a1.378,1.378,0,0,1,1.34,1.395v6.866h3.33v-6.866A4.773,4.773,0,0,0,1353.7,1417.1Z"
          transform="translate(-542.673 -957.438)"
          fill="#a0a0a0"
        />
        <path
          id="Path_1467"
          data-name="Path 1467"
          d="M1350.375,1412.8H1333.85a5.676,5.676,0,0,0-5.55,5.776v7.8h5.11v-7.8a.475.475,0,0,1,.461-.48H1350.4a.475.475,0,0,1,.461.48v7.8h5.111v-7.8A5.739,5.739,0,0,0,1350.375,1412.8Zm4.671,12.664h-3.33V1418.6a1.378,1.378,0,0,0-1.341-1.395H1333.85a1.378,1.378,0,0,0-1.34,1.395v6.866h-3.33V1418.6a4.772,4.772,0,0,1,4.67-4.86h16.525a4.772,4.772,0,0,1,4.671,4.86Z"
          transform="translate(-539.352 -954.076)"
          fill="#393e46"
        />
        <path
          id="Path_1468"
          data-name="Path 1468"
          d="M1495.816,1403.373h2.346v-2.441a.608.608,0,1,1,1.215,0v2.441h2.346a.633.633,0,0,1,0,1.264h-2.346v2.441a.621.621,0,0,1-.607.632.591.591,0,0,1-.44-.2.693.693,0,0,1-.188-.458v-2.441H1495.8a.661.661,0,0,1-.44-.174.59.59,0,0,1-.168-.458A.649.649,0,0,1,1495.816,1403.373Z"
          transform="translate(-671.285 -944.3)"
        />
        <path
          id="Path_1469"
          data-name="Path 1469"
          d="M1257.949,1713.473h2.345v-2.441a.608.608,0,1,1,1.215,0v2.441h2.346a.633.633,0,0,1,0,1.264h-2.346v2.441a.621.621,0,0,1-.607.632.591.591,0,0,1-.44-.2.694.694,0,0,1-.189-.458v-2.441h-2.345a.661.661,0,0,1-.44-.174.692.692,0,0,1-.188-.458A.685.685,0,0,1,1257.949,1713.473Z"
          transform="translate(-483.223 -1186.811)"
        />
        <g id="Group_1038" data-name="Group 1038" transform="translate(828.239 520.669)">
          <path
            id="Path_1470"
            data-name="Path 1470"
            d="M1518.706,1697a2.923,2.923,0,1,1-2.806,2.921A2.866,2.866,0,0,1,1518.706,1697Zm0,1.787a1.134,1.134,0,0,0,0,2.267,1.1,1.1,0,0,0,1.089-1.133A1.132,1.132,0,0,0,1518.706,1698.787Z"
            transform="translate(-1515.9 -1697)"
          />
        </g>
        <g id="Group_1039" data-name="Group 1039" transform="translate(763.313 476.815)">
          <path
            id="Path_1471"
            data-name="Path 1471"
            d="M1208.706,1495.8a2.923,2.923,0,1,1-2.806,2.921A2.865,2.865,0,0,1,1208.706,1495.8Zm0,1.787a1.134,1.134,0,0,0,0,2.267,1.1,1.1,0,0,0,1.089-1.134A1.131,1.131,0,0,0,1208.706,1497.587Z"
            transform="translate(-1205.9 -1495.8)"
          />
        </g>
      </g>
      <path
        id="Path_1522"
        data-name="Path 1522"
        d="M329.307,175.3c0,22.9-17.074,42.2-40.372,48.124a60.7,60.7,0,0,1-11.493,1.772c-.188.011-.376.022-.566.03q-1.509.074-3.041.074-2.663,0-5.264-.223-1.443-.121-2.861-.309a58.586,58.586,0,0,1-24.808-9.23q-1.34-.891-2.616-1.854a53.245,53.245,0,0,1-4.508-3.8c-9.548-8.979-1.588-28.146,3.389-40.212C250.6,137.1,243.2,125.3,273.836,125.3S329.307,147.682,329.307,175.3Z"
        transform="translate(504.693 268.703)"
        fill="rgba(236,97,91,0.45)"
        opacity="0.196"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "JobPast"
}
</script>

<style lang="scss" scoped>
:root {
  .job-past-svg {
    #Path_1522 {
      fill: rgba(236, 97, 91, 0.45);
    }
    #Path_1469,
    #Path_1471,
    #Path_1468,
    #Path_1470 {
      fill: #393e46;
    }
    #Path_1456 {
      fill: #f4f7f6;
    }
  }
}
:root.dark-theme {
  .job-past-svg {
    #Path_1522 {
      fill: #e7eaec;
    }
    #Path_1469,
    #Path_1471,
    #Path_1468,
    #Path_1470 {
      fill: #e7eaec;
    }
    #Path_1456 {
      fill: #66666d;
    }
  }
}
</style>