<template>
  <svg class="edu-jain-svg" width="90" height="90" viewBox="0 0 90 90">
    <g id="Group_1651" data-name="Group 1651" transform="translate(-703 -740)">
      <path
        id="Path_1660"
        data-name="Path 1660"
        d="M78.184,13a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,78.184,13Z"
        transform="translate(702.974 728.184)"
        fill="#6c63ff"
      />
      <path
        id="Path_1661"
        data-name="Path 1661"
        d="M57,12a45,45,0,1,0,45,45A45,45,0,0,0,57,12Z"
        transform="translate(691 728)"
      />
      <path
        id="Path_1662"
        data-name="Path 1662"
        d="M84.737,12a4.737,4.737,0,1,0,4.737,4.737A4.737,4.737,0,0,0,84.737,12Z"
        transform="translate(703.526 728)"
        fill="#6c63ff"
      />
      <path
        id="Path_1663"
        data-name="Path 1663"
        d="M14.368,24a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,14.368,24Z"
        transform="translate(691 730.21)"
        fill="#ee3e54"
      />
      <path
        id="Path_1664"
        data-name="Path 1664"
        d="M89.974,75.184a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,89.974,75.184ZM17.737,74a4.737,4.737,0,1,0,4.737,4.737A4.737,4.737,0,0,0,17.737,74Z"
        transform="translate(691.185 739.421)"
        fill="#6c63ff"
      />
      <path
        id="Path_1665"
        data-name="Path 1665"
        d="M24.368,81a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,24.368,81Z"
        transform="translate(692.842 740.71)"
        fill="#ee3e54"
      />
      <path
        id="Path_1666"
        data-name="Path 1666"
        d="M75.967,81.382a2.94,2.94,0,1,0,2.94,2.94A2.94,2.94,0,0,0,75.967,81.382ZM16.184,47a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,16.184,47Z"
        transform="translate(691.553 734.447)"
        fill="#fff"
      />
      <path
        id="Path_1667"
        data-name="Path 1667"
        d="M86.184,30a1.184,1.184,0,1,0,1.184,1.184A1.184,1.184,0,0,0,86.184,30Z"
        transform="translate(704.447 731.316)"
        fill="#6c63ff"
      />
      <path
        id="Path_1668"
        data-name="Path 1668"
        d="M80.368,64a2.368,2.368,0,1,0,2.368,2.368A2.368,2.368,0,0,0,80.368,64Z"
        transform="translate(703.158 737.579)"
        fill="#fff"
      />
      <g id="Group_1133" data-name="Group 1133" transform="translate(6.053 30.605)">
        <g id="Group_1123" data-name="Group 1123" transform="translate(721.947 734.395)">
          <path
            id="Path_1669"
            data-name="Path 1669"
            d="M28.7,28.762A3.132,3.132,0,0,1,31.89,25.7H54.25a1.048,1.048,0,0,1,1.058,1.036V50.464L32.567,50.4,28.7,52.583V28.762Z"
            transform="translate(-28.091 -25.104)"
            fill="#97aec7"
          />
          <path
            id="Path_1670"
            data-name="Path 1670"
            d="M54.159,26.191a.445.445,0,0,1,.45.44V49.763L32.642,49.7h-.329l-.284.161-2.811,1.59V28.658A2.529,2.529,0,0,1,31.8,26.191H54.159m0-1.191H31.8A3.73,3.73,0,0,0,28,28.658V53.511l4.637-2.624,23.189.071V26.631A1.649,1.649,0,0,0,54.159,25Z"
            transform="translate(-28 -25)"
            fill="#6d5856"
          />
          <path
            id="Path_1671"
            data-name="Path 1671"
            d="M29.437,52.652h2.99V26.375h-.435a2.528,2.528,0,0,0-2.555,2.5Z"
            transform="translate(-28.187 -25.205)"
            fill="#b0c1d4"
          />
          <path
            id="Path_1672"
            data-name="Path 1672"
            d="M31.57,60.317a2.809,2.809,0,1,1,0-5.617H54.849a5.882,5.882,0,0,0,.02,5.617Z"
            transform="translate(-28.091 -29.423)"
            fill="#fddbbc"
          />
          <path
            id="Path_1673"
            data-name="Path 1673"
            d="M53.84,55.191a6.364,6.364,0,0,0,.023,4.426H31.478a2.213,2.213,0,1,1,0-4.426H53.84M55.826,54H31.478a3.405,3.405,0,1,0,0,6.809H55.826a5.576,5.576,0,0,1,0-6.809Z"
            transform="translate(-28 -29.319)"
            fill="#6d5856"
          />
          <g id="Group_1121" data-name="Group 1121" transform="translate(3.478 3.404)">
            <path
              id="Path_1674"
              data-name="Path 1674"
              d="M32.435,32.4A.43.43,0,0,1,32,31.979V29.426a.435.435,0,0,1,.87,0v2.553A.43.43,0,0,1,32.435,32.4Z"
              transform="translate(-32 -29)"
              fill="#6d5856"
            />
          </g>
          <g id="Group_1122" data-name="Group 1122" transform="translate(3.478 7.66)">
            <path
              id="Path_1675"
              data-name="Path 1675"
              d="M32.435,50.17A.43.43,0,0,1,32,49.745V34.426a.435.435,0,0,1,.87,0V49.745A.43.43,0,0,1,32.435,50.17Z"
              transform="translate(-32 -34)"
              fill="#6d5856"
            />
          </g>
        </g>
        <g id="Group_1124" data-name="Group 1124" transform="translate(734.121 742.905)">
          <path
            id="Path_1676"
            data-name="Path 1676"
            d="M42.7,38.762A3.132,3.132,0,0,1,45.89,35.7H68.362a.938.938,0,0,1,.947.927V59.535a.937.937,0,0,1-.946.926l-21.8-.066L42.7,62.583V38.762Z"
            transform="translate(-42.091 -35.104)"
            fill="#ec615b"
          />
          <path
            id="Path_1677"
            data-name="Path 1677"
            d="M68.27,36.191a.336.336,0,0,1,.338.331V59.431a.335.335,0,0,1-.339.331L46.642,59.7h-.329l-.284.161-2.811,1.59V38.658A2.529,2.529,0,0,1,45.8,36.191H68.27m0-1.191H45.8A3.73,3.73,0,0,0,42,38.658V63.511l4.637-2.624,21.628.066h.005a1.54,1.54,0,0,0,1.556-1.523V36.523A1.539,1.539,0,0,0,68.27,35Z"
            transform="translate(-42 -35)"
            fill="#6d5856"
          />
        </g>
        <g id="Group_1125" data-name="Group 1125" transform="translate(735.371 744.076)">
          <path
            id="Path_1678"
            data-name="Path 1678"
            d="M43.437,62.652h2.99V36.375h-.435a2.528,2.528,0,0,0-2.555,2.5Z"
            transform="translate(-43.437 -36.375)"
            fill="rgba(255,221,219,0.45)"
          />
        </g>
        <g id="Group_1126" data-name="Group 1126" transform="translate(734.121 767.586)">
          <path
            id="Path_1679"
            data-name="Path 1679"
            d="M45.57,70.317a2.809,2.809,0,1,1,0-5.617H68.85a5.827,5.827,0,0,0-.2,5.13.333.333,0,0,1-.036.325.365.365,0,0,1-.308.161H45.57Z"
            transform="translate(-42.091 -64.104)"
            fill="#fddbbc"
          />
          <path
            id="Path_1680"
            data-name="Path 1680"
            d="M67.834,65.191a6.282,6.282,0,0,0,.026,4.426H45.478a2.213,2.213,0,1,1,0-4.426H67.834M69.826,64H45.478a3.405,3.405,0,1,0,0,6.809H68.211a.955.955,0,0,0,.906-1.308,5.417,5.417,0,0,1,.709-5.5Z"
            transform="translate(-42 -64)"
            fill="#6d5856"
          />
        </g>
        <g id="Group_1127" data-name="Group 1127" transform="translate(737.6 746.31)">
          <path
            id="Path_1681"
            data-name="Path 1681"
            d="M46.435,42.4A.43.43,0,0,1,46,41.979V39.426a.435.435,0,0,1,.87,0v2.553A.43.43,0,0,1,46.435,42.4Z"
            transform="translate(-46 -39)"
            fill="#6d5856"
          />
        </g>
        <g id="Group_1128" data-name="Group 1128" transform="translate(737.6 753.969)">
          <path
            id="Path_1682"
            data-name="Path 1682"
            d="M46.435,60.766A.43.43,0,0,1,46,60.34V48.426a.435.435,0,0,1,.87,0V60.34A.43.43,0,0,1,46.435,60.766Z"
            transform="translate(-46 -48)"
            fill="#6d5856"
          />
        </g>
        <g id="Group_1129" data-name="Group 1129" transform="translate(737.6 750.565)">
          <path
            id="Path_1683"
            data-name="Path 1683"
            d="M46.435,46.314A.43.43,0,0,1,46,45.889V44.426a.435.435,0,0,1,.87,0v1.463A.43.43,0,0,1,46.435,46.314Z"
            transform="translate(-46 -44)"
            fill="#6d5856"
          />
        </g>
        <g id="Group_1130" data-name="Group 1130" transform="translate(743.034 749.927)">
          <path
            id="Path_1684"
            data-name="Path 1684"
            d="M62.9,49.633H52.467a.215.215,0,0,1-.217-.213V43.463a.215.215,0,0,1,.217-.213H53.2a.213.213,0,1,1,0,.426h-.517v5.532H62.9a.213.213,0,1,1,0,.426Z"
            transform="translate(-52.25 -43.25)"
            fill="#eaeced"
          />
        </g>
        <g id="Group_1131" data-name="Group 1131" transform="translate(747.491 749.927)">
          <path
            id="Path_1685"
            data-name="Path 1685"
            d="M66.179,49.633H64.957a.213.213,0,1,1,0-.426h1.005V43.676h-8.37a.213.213,0,1,1,0-.426h8.587a.215.215,0,0,1,.217.213V49.42A.215.215,0,0,1,66.179,49.633Z"
            transform="translate(-57.375 -43.25)"
            fill="#eaeced"
          />
        </g>
        <g id="Group_1132" data-name="Group 1132" transform="translate(744.774 749.927)">
          <path
            id="Path_1686"
            data-name="Path 1686"
            d="M56.207,43.676H54.467a.213.213,0,1,1,0-.426h1.739a.213.213,0,1,1,0,.426Z"
            transform="translate(-54.25 -43.25)"
            fill="#eaeced"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "EduJain"
}
</script>

<style lang="scss" scoped>
:root {
  .edu-jain-svg {
    #Path_1661 {
      fill: #efeeff;
    }
  }
}
:root.dark-theme {
  .edu-jain-svg {
    #Path_1661 {
      fill: #40414b;
    }
  }
}
</style>