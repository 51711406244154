<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="85"
    height="95"
    viewBox="0 0 85 95"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="0.425"
        cy="0.499"
        r="0.55"
        gradientTransform="translate(-0.105) scale(1.209 1)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#d4943b" />
        <stop offset="1" stop-color="#c2620e" />
      </radialGradient>
      <radialGradient
        id="radial-gradient-2"
        cx="0.403"
        cy="0.498"
        r="0.849"
        gradientTransform="translate(-0.099) scale(1.198 1)"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#fed100" />
        <stop offset="0.245" stop-color="#fdce00" />
        <stop offset="0.461" stop-color="#f9c300" />
        <stop offset="0.665" stop-color="#f3b100" />
        <stop offset="0.862" stop-color="#eb9801" />
        <stop offset="1" stop-color="#e38101" />
      </radialGradient>
      <linearGradient
        id="linear-gradient"
        x1="-0.399"
        y1="1.142"
        x2="1.021"
        y2="-0.281"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stop-color="#f44f5a" />
        <stop offset="0.443" stop-color="#ee3d4a" />
        <stop offset="1" stop-color="#e52030" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-0.142"
        y1="1.399"
        x2="1.281"
        y2="-0.021"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-3"
        x1="0.5"
        y1="-0.246"
        x2="0.5"
        y2="1.547"
        xlink:href="#linear-gradient"
      />
      <linearGradient
        id="linear-gradient-4"
        x1="0.5"
        y1="-0.143"
        x2="0.5"
        y2="1.403"
        xlink:href="#linear-gradient"
      />
    </defs>
    <g id="pizz" transform="translate(-8.275 -3)">
      <path
        id="Path_2011"
        data-name="Path 2011"
        d="M74.743,16.915l-1.576,1.457L46.425,43.058a1.239,1.239,0,0,1-2.093-.8V5.287a2.352,2.352,0,0,1,2.634-2.275A53.7,53.7,0,0,1,74.594,13.57,2.161,2.161,0,0,1,74.743,16.915ZM90.8,52.775H50.785a1.122,1.122,0,0,0-.862,1.934L76.64,79.42l1.576,1.457a2.6,2.6,0,0,0,3.62-.136,45.685,45.685,0,0,0,11.426-25.53A2.326,2.326,0,0,0,90.8,52.775ZM74.743,84.084l-1.576-1.457L46.425,57.941a1.239,1.239,0,0,0-2.093.8V95.713a2.353,2.353,0,0,0,2.634,2.275A53.717,53.717,0,0,0,74.594,87.43,2.161,2.161,0,0,0,74.743,84.084Zm7.091-63.826a2.6,2.6,0,0,0-3.62-.136l-1.576,1.457L49.923,46.29a1.122,1.122,0,0,0,.862,1.934H90.8a2.321,2.321,0,0,0,2.462-2.434A45.685,45.685,0,0,0,81.835,20.258ZM37.315,57.941,10.573,82.628,9,84.084a2.163,2.163,0,0,0,.147,3.345A53.736,53.736,0,0,0,36.771,97.988a2.352,2.352,0,0,0,2.634-2.275V58.738A1.236,1.236,0,0,0,37.315,57.941Z"
        transform="translate(0 0)"
        fill="url(#radial-gradient)"
      />
      <path
        id="Path_2012"
        data-name="Path 2012"
        d="M38.049,56.331V91.282A48.8,48.8,0,0,1,9.214,80.223L35.956,55.535A1.239,1.239,0,0,1,38.049,56.331Zm4.925,0V91.282A48.8,48.8,0,0,0,71.808,80.223L45.066,55.535A1.239,1.239,0,0,0,42.973,56.331Zm2.093-15.679L71.808,15.965A48.8,48.8,0,0,0,42.973,4.907V39.858A1.239,1.239,0,0,0,45.066,40.652Zm3.5,11.651L75.281,77.014A41.581,41.581,0,0,0,87.248,50.369H49.426A1.122,1.122,0,0,0,48.564,52.3Zm.862-6.485H87.248A41.581,41.581,0,0,0,75.281,19.173L48.564,43.884A1.122,1.122,0,0,0,49.426,45.818Z"
        transform="translate(1.359 2.407)"
        fill="url(#radial-gradient-2)"
      />
      <path
        id="Path_2013"
        data-name="Path 2013"
        d="M29.806,13.1a4.851,4.851,0,0,0,1.219-4.985L24.061,14.55S27.544,15.19,29.806,13.1Z"
        transform="translate(22.854 6.455)"
        fill="#33c481"
      />
      <path
        id="Path_2014"
        data-name="Path 2014"
        d="M31.113,8.271A5.466,5.466,0,0,0,25.718,9.4a5.532,5.532,0,0,0-1.567,5.309Z"
        transform="translate(22.766 6.296)"
        fill="#21a366"
      />
      <path
        id="Path_2015"
        data-name="Path 2015"
        d="M40.806,34.11a4.851,4.851,0,0,0,1.219-4.985L35.061,35.56S38.544,36.2,40.806,34.11Z"
        transform="translate(38.779 32.967)"
        fill="#33c481"
      />
      <path
        id="Path_2016"
        data-name="Path 2016"
        d="M42.113,29.281a5.466,5.466,0,0,0-5.395,1.126,5.532,5.532,0,0,0-1.567,5.309Z"
        transform="translate(38.691 32.809)"
        fill="#21a366"
      />
      <path
        id="Path_2017"
        data-name="Path 2017"
        d="M36.459,23.11a4.851,4.851,0,0,1-1.219-4.985L42.2,24.56S38.723,25.2,36.459,23.11Z"
        transform="translate(38.691 19.086)"
        fill="#33c481"
      />
      <path
        id="Path_2018"
        data-name="Path 2018"
        d="M35.1,18.281a5.466,5.466,0,0,1,5.395,1.126,5.532,5.532,0,0,1,1.567,5.309Z"
        transform="translate(38.833 18.928)"
        fill="#21a366"
      />
      <path
        id="Path_2019"
        data-name="Path 2019"
        d="M34.655,30.077a5.574,5.574,0,0,1-4.678-2.827h9.847A6.945,6.945,0,0,1,34.655,30.077Z"
        transform="translate(31.419 30.601)"
        fill="#33c481"
      />
      <path
        id="Path_2020"
        data-name="Path 2020"
        d="M29.977,28.827A5.126,5.126,0,0,1,34.655,26a6.373,6.373,0,0,1,5.17,2.827Z"
        transform="translate(31.419 29.024)"
        fill="#21a366"
      />
      <path
        id="Path_2021"
        data-name="Path 2021"
        d="M29,40.3c0-2.956,3.06-4.322,3.06-4.322v9.1S29,43.256,29,40.3Z"
        transform="translate(30.004 41.613)"
        fill="#33c481"
      />
      <path
        id="Path_2022"
        data-name="Path 2022"
        d="M30.25,35.977A4.836,4.836,0,0,1,33.31,40.3c0,3.185-3.06,4.777-3.06,4.777Z"
        transform="translate(31.814 41.613)"
        fill="#21a366"
      />
      <path
        id="Path_2023"
        data-name="Path 2023"
        d="M18.806,41.1a4.851,4.851,0,0,0,1.219-4.985L13.061,42.55S16.544,43.19,18.806,41.1Z"
        transform="translate(6.929 41.788)"
        fill="#33c481"
      />
      <path
        id="Path_2024"
        data-name="Path 2024"
        d="M20.113,36.271A5.466,5.466,0,0,0,14.718,37.4a5.532,5.532,0,0,0-1.567,5.309Z"
        transform="translate(6.84 41.629)"
        fill="#21a366"
      />
      <path
        id="Path_2025"
        data-name="Path 2025"
        d="M39.76,14.267,26.346,26.617A8.469,8.469,0,0,1,25,22.048c0-5,4.381-9.048,9.791-9.048A10.192,10.192,0,0,1,39.76,14.267Z"
        transform="translate(24.213 12.619)"
        fill="url(#linear-gradient)"
      />
      <path
        id="Path_2026"
        data-name="Path 2026"
        d="M41.715,19.562c0,5-4.381,9.048-9.791,9.048a10.334,10.334,0,0,1-4.944-1.244l13.365-12.4A8.361,8.361,0,0,1,41.715,19.562Z"
        transform="translate(27.08 15.105)"
        fill="url(#linear-gradient-2)"
      />
      <path
        id="Path_2027"
        data-name="Path 2027"
        d="M23.871,31.55V47.157a8.686,8.686,0,0,1,0-15.607Z"
        transform="translate(15.527 36.027)"
        fill="url(#linear-gradient-3)"
      />
      <path
        id="Path_2028"
        data-name="Path 2028"
        d="M32.777,40.048c0,5-4.357,9.025-9.767,9.048V31C28.42,31.023,32.777,35.049,32.777,40.048Z"
        transform="translate(21.332 35.333)"
        fill="url(#linear-gradient-4)"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "GithubPizzaStore"
}
</script>
